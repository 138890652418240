import { Homepage } from "./screen/index";
// import ScreenLoader from "./screen/loadinPage";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Homepage />
    </div>
  );
};

export default App;
